import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import store from '@/store'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
//import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlChat
    api.defaults.quietly = false
    return api
  },

  async allUnread() {
    const response = await this.base().get('/v1/unread')

    for (let message of response.data) {
      message.created_date_obj = Dates.build(message.created_date)
    }
    response.data.sort((a, b) => {
      if (a.created_date_obj < b.created_date_obj) return 1
      else if (a.created_date_obj > b.created_date_obj) return -1
      else return a.key.localeCompare(b.key)
    })
    return response.data
  },
  async getChannels(type = null, limit = null, page = null, archived = false) {
    let data = null
    if (type || limit || page || archived) {
      const dataParams = {}
      if (type) dataParams.type = type
      if (limit) dataParams.limit = limit
      if (page) dataParams.page = page
      if (archived) dataParams.archived = archived
      data = {
        params: dataParams,
        paramsSerializer: params => {
          return querystring.stringify(params)
        }
      }
    }

    const response = await this.base().get('/v1/channels', data)

    for (let channel of response.data.channels) {
      if (channel.last) channel.last_date_obj = Dates.build(channel.last.date)
      else channel.last_date_obj = null
    }
    response.data.channels.sort((a, b) => {
      if (a.last_date_obj < b.last_date_obj) return 1
      else if (a.last_date_obj > b.last_date_obj) return -1
      else return a.title.localeCompare(b.title)
    })
    return response.data
  },
  async getChannel(key) {
    const response = await this.base().get(`/v1/channel/${key}`)
    return response.data
  },

  async getMessages(
    chatKey,
    quietly = false,
    afterKey = null,
    beforeKey = null,
    subscribe = false,
    filter = null,
    filterType = null,
    query = null
  ) {
    const orgArgs = Array.from(arguments)
    const callback = this.getMessages

    const data = {
      quietly: quietly,
      params: {}
    }

    if (filter) {
      data.params.filter = filter
    }
    if (filter && filterType) {
      data.params.filter_type = filterType
    }

    if (query) {
      data.params.query = query
    }
    data.params.with_users = true

    if (subscribe) data.params.subscribe_connection_id = store.state.socket.connectionId

    if (afterKey) data.params.after_key = afterKey
    else if (beforeKey) data.params.before_key = beforeKey

    const config = { ignoreResponseLog: true, ignoreInvalidConnectionID: true }

    try {
      const response = await this.base(config).get(`/v1/channel/${chatKey}/messages`, data)
      if (subscribe) console.debug('Chat subscribe on get messages', chatKey)
      return response.data
    } catch (error) {
      //retry!
      if (utils.errors(error).exists('InvalidConnectionID')) {
        store.commit('checkWebsocket', {
          retryCallback: callback,
          retryCallbackArgs: orgArgs,
          retryCallbackWait: 100,
          retryCallbackExpectReturn: true
        })
        return store.state.callbackReturnData
      } else {
        throw error
      }
    }
  },
  getMessagesAndSubscribe(chatKey, quietly = false, afterKey = null) {
    return this.getMessages(chatKey, quietly, afterKey, null, true)
  },
  async getChatUsers(chatKey, userKeys = null) {
    const data = {
      paramsSerializer: params => {
        return querystring.stringify(params)
      }
    }
    if (userKeys) {
      data.params = { key: userKeys }
    }

    const response = await this.base().get(`/v1/channel/${chatKey}/users`, data)
    return response.data
  },

  async addUser(email) {
    const response = await this.base().post('/v1/channels', { email: email })
    return response.data
  },

  addMessage(
    chatKey,
    messageText,
    files = [],
    replyTo = null,
    isAnnouncement = false,
    annoucementDuration = null,
    notifyUsers = false
  ) {
    const config = { ignoreDataLog: true, ignoreResponseLog: true }
    let messageData = {
      message: messageText,
      files: files,
      ignore_connection_id: store.state.socket.connectionId
    }

    if (isAnnouncement) {
      messageData['is_announcement'] = isAnnouncement
      messageData['announcement_expiry'] = annoucementDuration
      messageData['notify_users'] = notifyUsers
    }

    if (replyTo) {
      messageData['action'] = {
        type: 'reply',
        message_key: replyTo.key
      }
    }
    return this.base(config).post(`/v1/channel/${chatKey}/messages`, messageData)
  },
  async promoteMessage(chatKey, messageKey, duration = null, notify = false) {
    const config = { ignoreDataLog: true, ignoreResponseLog: true }
    const response = await this.base(config).post(`/v1/channel/${chatKey}/message/${messageKey}`, {
      is_announcement: true,
      announcement_expiry: duration,
      notify_users: notify,
      ignore_connection_id: store.state.socket.connectionId
    })
    return response.data
  },
  async unpromoteMessage(chatKey, messageKey) {
    const config = { ignoreDataLog: true, ignoreResponseLog: true }
    const response = await this.base(config).post(`/v1/channel/${chatKey}/message/${messageKey}`, {
      data: {
        is_announcement: false,
        ignore_connection_id: store.state.socket.connectionId
      }
    })
    return response.data
  },
  async getChatFiles(chatKey) {
    const response = await this.base().get(`/v1/channel/${chatKey}/files`)
    return response.data
  },
  deleteMessage(chatKey, messageKey) {
    return this.base().delete(`/v1/channel/${chatKey}/message/${messageKey}`, {
      data: {
        ignore_connection_id: store.state.socket.connectionId
      }
    })
  },
  async subscribe(key) {
    store.commit('sendWSMessage', { application: 'chat', code: 'subscribe', key: key })
    console.debug('Chat subscribe', key)
  },
  async unsubscribe(key) {
    store.commit('sendWSMessage', { application: 'chat', code: 'unsubscribe', key: key })
    console.debug('Chat unsubscribe', key)
  },
  async editMessage(chatKey, messageKey, messageText = null, isAnouncement = null) {
    let data = {}
    if (messageText) data.message = messageText
    if (isAnouncement) data.is_anouncement = isAnouncement
    return this.base().put(`/v1/channel/${chatKey}/message/${messageKey}`, data)
  },
  async silentChat(chatKey) {
    ///v1/channel/{{chatChannelKey}}/silent
    const response = await this.base().post(`/v1/channel/${chatKey}/silent`)
    return response.data
  },
  async unsilentChat(chatKey) {
    //{{chatHost}}/v1/channel/{{chatChannelKey}}/silent
    const response = await this.base().delete(`/v1/channel/${chatKey}/silent`)
    return response.data
  },
  async archiveChat(chatKey) {
    //const response = await this.base().post('/v1/channels/archive', { keys: chatKeys })
    const response = await this.base().post(`/v1/channels/archive`, {
      keys: [chatKey]
    })
    return response.data
  },
  async unarchiveChat(chatKey) {
    const response = await this.base().delete(`/v1/channel/${chatKey}/archive`)
    return response.data
  }
}
