import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'
import ChatUtils from '@/fw-modules/fw-core-vue/chats/services/utilities'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  data() {
    return this.getChatDefaultData()
  },

  beforeDestroy() {
    this.clearSelfUnreadInterval()
  },

  computed: {
    chatAuthUser() {
      return this.$store.getters.getUser
    }
  },

  methods: {
    getChatDefaultData() {
      return {
        chats: [],
        chatsRef: {},

        chatAllUnread: '',
        chatMainUnread: '',

        chatUsers: [],
        chatOnLoadGoToUser: {},
        allChatUsers: {},

        chatsData: {},
        chatActive: {
          loading: false,
          key: null,
          title: '',
          type: null,
          user: null,
          meeting: null,
          isManager: false,
          messages: [],
          message: '',
          files: [],
          selfUnread: 0,
          interval: null,
          withOld: false,
          html: null,
          is_archived: false,
          is_silent: false
        },
        chatPagination: {
          active_limit: 30,
          current_page: 1,
          total_items: 1,
          total_pages: 1
        },
        chatArchived: {
          class: 0,
          group: 0,
          user: 0
        },
        chatLocked: false
      }
    },
    getChatData(key) {
      let data = this.chatsData[key]
      if (data === undefined) {
        data = this.chatsData[key] = {
          messages: [],
          withOld: false,
          loadingOld: false,
          message: '',
          files: [],
          users: [],
          users_loaded: false //avoid calling users endpoint multiple times
        }
      }
      return data
    },
    saveChatData(data) {
      this.getChatData(data.key).message = data.message
      this.getChatData(data.key).is_archived = data.is_archived
      this.getChatData(data.key).is_silent = data.is_silent
    },
    selfUnreadAtBottom() {
      if (this.chatActive && (!this.chatActive.selfUnread || ChatUtils.scrollIsAtBottom())) {
        this.chatActive.selfUnread = 0
        this.clearSelfUnreadInterval()
      }
    },
    clearSelfUnreadInterval() {
      if (this.chatActive && this.chatActive.interval) {
        clearInterval(this.chatActive.interval)
        this.chatActive.interval = null
      }
    },

    registerChat() {
      this.$store.commit('subscribeWS', { code: 'ws-reconnect', name: 'ChatLive', callback: this.WSChatReconnect })
      this.$store.commit('subscribeWS', { code: 'chat', name: 'ChatLive', callback: this.setWSChatMessages })
    },
    unregisterChat() {
      this.$store.commit('unsubscribeWS', { code: 'ws-reconnect', name: 'ChatLive' })
      this.$store.commit('unsubscribeWS', { code: 'chat', name: 'ChatLive' })
    },
    async WSChatReconnect() {
      if (this.chatActive && this.chatActive.key) {
        this.loadChat({ key: this.chatActive.key, title: this.chatActive.title }, false, true)
      }
    },
    setAllChatUsers(users) {
      for (const [key, user] of Object.entries(users)) {
        this.allChatUsers[key] = user
      }
    },
    async setWSChatMessages(messages) {
      if (!messages.newMessage && !messages.deleteMessage) return

      let unread = {}
      const currentKey = this.chatActive ? this.chatActive.key : null

      if (messages.newMessage) {
        const newMessages = []
        const toTopKeys = []
        for (let message of messages.newMessage) {
          if (!toTopKeys.includes(message.channel_key)) {
            toTopKeys.push(message.channel_key)
          }

          if (currentKey && currentKey == message.channel_key) {
            newMessages.push(message.message)
            if (!this.allChatUsers[message.message.user_key]) {
              this.allChatUsers[message.message.user_key] = message.user
            }
          } else {
            if (unread[message.channel_key]) unread[message.channel_key] += 1
            else unread[message.channel_key] = 1
          }
        }

        if (newMessages.length) {
          const isAtBottom = ChatUtils.scrollIsAtBottom()
          await this.setChatMessages(currentKey, newMessages)
          if (isAtBottom) {
            ChatUtils.scrollToBottom()
          } else if (this.chatActive) {
            this.chatActive.selfUnread += 1
            if (!this.chatActive.interval) {
              this.chatActive.interval = setInterval(this.selfUnreadAtBottom, 500)
            }
          }
        }

        if (toTopKeys.length) {
          for (let idx = this.chats.length - 1; idx >= 0; idx--) {
            if (toTopKeys.includes(this.chats[idx].key)) {
              this.chats.splice(idx, 1)
            }
          }
          for (let channelKey of toTopKeys) {
            const chat = this.chatsRef[channelKey]
            if (chat) this.chats.unshift(chat)
          }

          if (this.chatUsers.length) {
            const addToTop = []
            for (let idx = this.chatUsers.length - 1; idx >= 0; idx--) {
              const chatUser = this.chatUsers[idx]
              if (toTopKeys.includes(chatUser.chat.key)) {
                addToTop.unshift(chatUser)
                this.chatUsers.splice(idx, 1)
              }
            }
            if (addToTop.length) {
              this.chatUsers.unshift(...addToTop)
            }
          }
        }
      }

      if (messages.deleteMessage) {
        const deleteKeys = []
        for (let message of messages.deleteMessage) {
          if (currentKey && currentKey == message.channel_key) {
            deleteKeys.push(message.key)
          } else if (unread[message.channel_key]) {
            unread[message.channel_key] -= 1
          }
        }
        if (deleteKeys.length) {
          this.deleteMessages(currentKey, deleteKeys)
        }
      }

      if (this.chats.length) {
        for (const chat of this.chats) {
          let chatUnread = unread[chat.key]
          if (chatUnread) {
            let newUnread = chat.unread + chatUnread
            if (newUnread > 0) chat.unread = newUnread
            else chat.unread = 0
          }
        }
      }

      for (let user of this.chatUsers) {
        let userUnread = unread[user.chat.key]
        if (userUnread) {
          let newUnread = user.chat.unread + userUnread
          if (newUnread > 0) user.chat.unread = newUnread
          else user.chat.unread = 0
        }
      }

      this.setAllChatUnread()
    },

    async loadMainChat(callback) {
      if (this.chatOnLoadGoToUser && this.chatOnLoadGoToUser.key) {
        this.loadChat(this.chatOnLoadGoToUser, true, false, callback)
      } else if (this.chats.length) {
        this.loadChat(this.chats[0], true, false, callback)
      } else if (callback) {
        callback(false)
      }
    },
    async unloadChat(key, force = false) {
      await utils.sleep(500)
      while (this.chatLocked) await utils.sleep(50)
      this.chatLocked = true

      try {
        if (this.chatActive.key && this.chatActive.key === key && (force || !this.isInChatView())) {
          ServiceChat.unsubscribe(this.chatActive.key)
          this.$store.dispatch('removeActiveChat', this.chatActive.key)
          await utils.sleep(100) // Make sure this arrive before "getMessagesAndSubscribe"

          this.saveChatData(this.chatActive)
          this.chatActive.key = null
          this.chatActive.type = null
          this.chatActive.user = null
          this.chatActive.meeting = null
          this.chatActive.isManager = false
          this.chatActive.messages = []
          this.chatActive.users = []
          this.chatActive.message = ''
          this.chatActive.files = []
          this.chatActive.selfUnread = 0
          this.chatActive.withOld = false
          this.chatActive.is_archived = false
          this.chatActive.is_silent = false
          this.clearSelfUnreadInterval()
          if (this.chatActive.html) {
            this.chatActive.html.removeEventListener('scroll', this.watchChatScroll)
            this.chatActive.html = null
          }
        }
      } finally {
        this.chatLocked = false
      }
    },
    async loadChat(chatOrUser, quietly = false, force = false, callback = null) {
      while (this.chatLocked) await utils.sleep(50)
      this.chatLocked = true

      let chat = chatOrUser
      if (chatOrUser.chat) {
        // This is a user object
        chat = chatOrUser.chat
        chat.title = chatOrUser.name
      }

      try {
        await this.loadChatCore(chat, quietly, force)
        if (callback) callback(true)
      } finally {
        this.chatLocked = false
      }
    },
    async loadChatCore(chat, quietly = false, force = false) {
      this.chatActive.loading = true
      try {
        if (this.chatActive.key) {
          if (!force && this.chatActive.key === chat.key) return
          this.saveChatData(this.chatActive)
          ServiceChat.unsubscribe(this.chatActive.key)
        }
        /*try {
          //loading all users of the chat
          await this.loadMessagesUsers(chat.key, [], true)
        } catch (error) {
          console.error('loadMessagesUsers', error)
        }*/

        this.chatActive.key = chat.key
        this.chatActive.title = chat.title
        this.chatActive.type = chat.type
        this.chatActive.user = null
        this.chatActive.users = this.getChatData(chat.key).users //get latest users
        this.chatActive.is_silent = chat.is_silent
        this.chatActive.is_archived = chat.is_archived
        if (chat.type == 'user') {
          this.chatActive.user = {
            initials: chat.initials,
            photo: chat.icon
          }
        }
        if (chat.type === 'group' && chat.meeting) {
          this.chatActive.meeting = await ServiceMeetings.getMeeting(chat.meeting.key)
        } else {
          this.chatActive.meeting = null
        }

        const afterKey = this.getLastChatMessageKey(chat.key)
        let response = await ServiceChat.getMessagesAndSubscribe(chat.key, quietly, afterKey)
        this.chatActive.isManager = response.is_manager || false
        //set all users from response
        this.setAllChatUsers(response.users)
        await this.loadMessagesUsers(chat.key, response.messages)
        await this.setChatMessages(chat.key, response.messages)

        const chatData = this.getChatData(chat.key)
        this.chatActive.selfUnread = 0
        this.clearSelfUnreadInterval()
        console.log('messages', chatData.messages)
        this.chatActive.messages = chatData.messages ? chatData.messages : []
        this.chatActive.message = chatData.message
        this.chatActive.files = chatData.files
        if (chatData.is_silent !== undefined) {
          this.chatActive.is_silent = chatData.is_silent
        }
        if (chatData.is_archived !== undefined) {
          this.chatActive.is_archived = chatData.is_archived
        }
        this.$store.dispatch('setActiveChat', chat.key)
        this.resetChatUnread(chat.key)

        if (afterKey) this.chatActive.withOld = chatData.withOld
        else this.chatActive.withOld = chatData.withOld = response.with_previous

        // Give DOM a break aka time to load the chat messages element
        this.$nextTick(async () => {
          let tries = 25
          while (tries > 0 && this.chatActive.key == chat.key) {
            let html = document.getElementById('chat-messages')
            if (html) {
              this.chatActive.html = html
              this.chatActive.html.addEventListener('scroll', this.watchChatScroll)
              break
            } else {
              await utils.sleep(50)
              tries -= 1
            }
          }
        })

        if (afterKey && response.with_next) {
          // TODO get missed new messages
        }

        ChatUtils.scrollToBottom(500)
      } catch (error) {
        const chatData = this.getChatData(chat.key)
        this.chatActive.selfUnread = 0
        this.chatActive.messages = chatData.messages ? chatData.messages : []
        this.chatActive.message = chatData.message
        this.chatActive.files = chatData.files
        this.chatActive.meeting = null
        this.chatActive.isManager = false
      } finally {
        this.chatActive.loading = false
      }
    },

    watchChatScroll() {
      if (this.chatActive.html && this.chatActive.withOld && this.chatActive.html.scrollTop <= 100) {
        this.loadOldMessagesCore(this.chatActive.key)
      }
    },
    async loadOldMessagesCore(key) {
      const chatData = this.getChatData(key)
      if (
        !chatData ||
        this.chatActive.key != key ||
        chatData.loadingOld ||
        !chatData.withOld ||
        !chatData.messages.length
      ) {
        return
      }

      chatData.loadingOld = true
      //get element with class loading-more-messages
      let loadingMoreMessages = document.getElementsByClassName('loading-more-messages')
      if (loadingMoreMessages && loadingMoreMessages.length) {
        loadingMoreMessages[0].classList.remove('hidden')
      }

      try {
        const beforeKey = chatData.messages[0].key
        const response = await ServiceChat.getMessages(key, true, null, beforeKey)
        if (!response.with_next) chatData.withOld = false

        await this.loadMessagesUsers(key, response.messages)

        let previousHeight = this.chatActive.key == key ? this.chatActive.html.scrollHeight : null
        await this.setChatMessages(key, response.messages, true)

        if (this.chatActive.key == key && previousHeight !== null) {
          const newHeight = this.chatActive.html.scrollHeight - previousHeight
          if (newHeight > 0) {
            this.chatActive.html.scrollTop = newHeight + this.chatActive.html.scrollTop
            previousHeight = this.chatActive.html.scrollHeight

            setTimeout(() => {
              const newHeight2 = this.chatActive.html.scrollHeight - previousHeight
              this.chatActive.html.scrollTop = newHeight2 + this.chatActive.html.scrollTop
            }, 250)
          }
        }
      } finally {
        chatData.loadingOld = false
        if (loadingMoreMessages && loadingMoreMessages.length) {
          loadingMoreMessages[0].classList.add('hidden')
        }
      }
    },

    async updateChat(key, title) {
      const chat = this.chatsRef[key]
      if (chat) chat.title = title
    },
    async updateActiveChat(data) {
      if (this.chatActive && this.chatActive.key) {
        const chat = this.chatsRef[this.chatActive.key]
        if (data.title) {
          this.chatActive.title = data.title
          if (chat) chat.title = data.title
        }
        if (typeof data.is_archived !== 'undefined') {
          this.$set(this.chatActive, 'is_archived', data.is_archived)
          if (chat) chat.is_archived = data.is_archived
        }
        if (typeof data.is_silent !== 'undefined') {
          this.$set(this.chatActive, 'is_silent', data.is_silent)
          if (chat) chat.is_archived = data.is_silent
        }
      }
    },
    async removeChat(key, callback) {
      if (this.chatActive.key && key === this.chatActive.key) {
        await this.unloadChat(this.chatActive.key, true)
      }

      if (this.chatsRef[key]) {
        delete this.chatsRef[key]
        for (let idx in this.chats) {
          if (this.chats[idx].key === key) {
            this.chats.splice(idx, 1)
            break
          }
        }
        this.setAllChatUnread()
      }

      this.loadMainChat(callback)
    },
    setPagination(pagination) {
      if (pagination) {
        this.chatPagination.active_limit = pagination.active_limit
        this.chatPagination.current_page = pagination.current_page
        this.chatPagination.total_items = pagination.total_items
        this.chatPagination.total_pages = pagination.total_pages
      }
    },
    setArchivedStats(stats) {
      if (stats) {
        this.chatArchived = stats
      }
    },
    setChat(chat, loadAfter = false, quietly = false, force = false, callback = null, toStart = false) {
      if (!this.chatsRef[chat.key]) {
        if (toStart) {
          this.chats.unshift(chat)
        } else {
          this.chats.push(chat)
        }

        this.chatsRef[chat.key] = chat
        this.setAllChatUnread()
      }

      if (loadAfter) {
        this.loadChat(chat, quietly, force, callback)
      }
    },
    setExamChat(exam) {
      if (exam.chat) {
        exam.chat.title = 'Supervisores'
        this.setChat(exam.chat)
      }

      if (exam.chat_users) {
        for (let user of Object.values(exam.chat_users)) this.addChatUser(user)
        this.sortChatUsers(true)
      }

      this.setAllChatUnread()
    },

    buildUnreadString(length) {
      if (length > 99) return '99'
      else if (length > 0) return length.toString()
      else return ''
    },
    setAllChatUnread() {
      let newUnread = 0
      if (this.chats.length) {
        this.chatMainUnread = this.buildUnreadString(this.chats[0].unread)

        for (const chat of this.chats) newUnread += chat.unread
      }
      for (let user of this.chatUsers) {
        newUnread += user.chat.unread
      }

      this.chatAllUnread = this.buildUnreadString(newUnread)
    },
    resetChatUnread(key) {
      const chat = this.chatsRef[key]
      if (chat) {
        chat.unread = 0
      } else {
        for (let user of this.chatUsers) {
          if (user.chat.key === key) {
            user.chat.unread = 0
            break
          }
        }
      }

      this.setAllChatUnread()
    },
    sortChatUsers(firstSort = false) {
      this.chatUsers.sort((a, b) => {
        if (a.chat.unread) {
          if (!b.chat.unread) {
            return -1
          } else if (!firstSort) {
            return 0
          }
        } else if (b.chat.unread) {
          return 1
        }

        return a.name.localeCompare(b.name)
      })
    },
    addChatUser(user) {
      if (user && user.chat) {
        let userExistsInChat = false
        for (let chatUser of this.chatUsers) {
          if (chatUser.key === user.key) {
            userExistsInChat = true
            break
          }
        }
        if (!userExistsInChat) {
          user.chat.title = user.name
          user.chat.type = 'user'
          user.chat.initials = user.initials
          user.chat.icon = user.photo
          this.chatUsers.push(user)
          this.allChatUsers[user.key] = user
        }
      }
    },

    removeAttendeeChat(attendee) {
      if (attendee.user && this.countUserAttendees(attendee.user.key) <= 1) {
        for (let i = 0; i < this.chatUsers.length; i++) {
          if (this.chatUsers[i].key === attendee.user.key) {
            this.chatUsers.splice(i, 1)
            break
          }
        }
      }
    },
    getLastChatMessageKey(chatKey) {
      let chatMessages = this.getChatData(chatKey).messages
      if (chatMessages.length) return chatMessages[chatMessages.length - 1].key
    },
    async loadMessagesUsers(chatKey, messages = []) {
      //messages
      var missingUserKeys = []
      for (let message of messages) {
        if (
          message.user_key !== this.chatAuthUser.key &&
          !this.allChatUsers[message.user_key] &&
          !missingUserKeys.includes(message.user_key)
        ) {
          missingUserKeys.push(message.user_key)
        }
      }
      //if (this.getChatData(chatKey).users_loaded) return
      if (missingUserKeys.length) {
        console.log('loading missing users', missingUserKeys)
        let users = await ServiceChat.getChatUsers(chatKey, missingUserKeys) //ServiceChat.getChatUsers(chatKey, missingUserKeys)
        let chatUsers = []
        for (const key in users) {
          this.allChatUsers[key] = users[key]
          chatUsers.push(users[key])
        }
        //this.getChatData(chatKey).users = { ...this.getChatData(chatKey).users, ...chatUsers }
      }
    },

    async setChatMessages(chatKey, messages, fromStart = false, toBottom = false, insitu = false) {
      const allFiles = []
      const allRecordings = {}
      const allQuiz = {}
      const allBucketItems = {}
      messages.forEach(message => {
        if (message.files && message.files.length) allFiles.push(...message.files)

        message.recordings = []
        message.quiz = []
        message.bucketItems = []
        if (message.message) {
          const urlsFound = utils.getUrlsFromString(message.message, true)
          if (urlsFound) {
            urlsFound.forEach(url => {
              const previewInfo = (url.split(FwEnvConfig.ucpreviewUrl)[1] || '')
                .replace(/\s/g, '')
                .replace('/', '')
                .split('-')

              const previewType = previewInfo[0]
              const previewKey = (previewInfo[1] || '').split('?')[0].split('<')[0]
              if (previewType && previewKey) {
                if (previewType === 'mrec') {
                  const itemRef = allRecordings[previewKey]
                  if (!itemRef) allRecordings[previewKey] = [message]
                  else if (!itemRef.includes(message)) itemRef.push(message)
                } else if (previewType === 'quiz') {
                  const itemRef = allQuiz[previewKey]
                  if (!itemRef) allQuiz[previewKey] = [message]
                  else if (!itemRef.includes(message)) itemRef.push(message)
                } else if (previewType === 'cid') {
                  const itemRef = allBucketItems[previewKey]
                  if (!itemRef) allBucketItems[previewKey] = [message]
                  else if (!itemRef.includes(message)) itemRef.push(message)
                }
              }
            })
          }
        }
      })

      if (allFiles.length) {
        await ServiceStorage.setFilesMetadata(allFiles, true)
      }

      const recordingKeys = Object.keys(allRecordings)
      if (recordingKeys.length) {
        for (let recording of Object.values(await ServiceMeetings.getPublicRecordings(recordingKeys, true))) {
          const messagesRef = allRecordings[recording.key]
          if (messagesRef) {
            for (let message of messagesRef) {
              message.recordings.push(recording)
            }
          }
        }
      }

      const bucketItemKeys = Object.keys(allBucketItems)
      if (bucketItemKeys.length) {
        for (let bucketItem of Object.values(await ServiceBuckets.getPublicItems(bucketItemKeys))) {
          const messagesRef = allBucketItems[bucketItem.key]
          if (messagesRef) {
            for (let message of messagesRef) {
              message.bucketItems.push(bucketItem)
            }
          }
        }
      }

      const chatMessages = this.getChatData(chatKey).messages
      if (insitu) {
        //relace the content of a message insitu
        for (let message of messages) {
          const idx = chatMessages.findIndex(m => m.key === message.key)
          if (idx >= 0) {
            chatMessages[idx] = message
          }
        }
      } else {
        if (fromStart) {
          chatMessages.unshift(...messages)
        } else {
          chatMessages.push(...messages)
        }
      }

      if (toBottom) ChatUtils.scrollToBottom()
    },
    deleteMessages(chatKey, keys) {
      const messages = this.getChatData(chatKey).messages
      if (messages.length) {
        for (let i = messages.length - 1; i >= 0; i--) {
          if (keys.includes(messages[i].key)) messages.splice(i, 1)
        }
      }
    },

    async deleteChatMessage(chatKey, key) {
      const response = await ServiceChat.deleteMessage(chatKey, key)
      if (response.status == 200) this.deleteMessages(chatKey, [key])
    }
  }
}
