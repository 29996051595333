<template>
  <div v-if="!file"></div>
  <v-zoomer v-else-if="file && isImage" class="h-full w-full">
    <div class="px-5 pt-5 transition-all duration-300">
      <img :src="fileViewUrl" class="object-contain" />
    </div>
  </v-zoomer>
  <div v-else-if="file && isVideo" class="flex items-center justify-center transition-all duration-300">
    <video-player :ref="'v-' + file.key" :src="fileViewUrl" />
  </div>
  <PanelPdfViewer v-else-if="file && isPdf" :file="file" :show-header="false" />
  <div v-else-if="file" class="flex items-center justify-center">
    <div class="p-5 text-gray-600 flex flex-col gap-2">
      <div class="font-bold text-lg">Pré-visualização não disponível</div>
      <p class="text-sm opacity-70">Descarregue o ficheiro para o seu dispositivo para o visualizar</p>

      <div
        class="bg-white/10 rounded-lg p-1 flex gap-2 items-center select-none cursor-default"
        @click="downloadFile()"
      >
        <div class="h-9 w-9 bg-gray-600 flex items-center justify-center">
          <fw-icon-file class="w-5 h-5 text-gray-300" />
        </div>
        <div class="font-semibold text-sm leading-4">
          {{ file.filename }}
        </div>
      </div>

      <fw-button icon="download" class="mx-auto mt-3" @click.native="downloadFile()">Descarregar</fw-button>
    </div>
  </div>
</template>

<script>
import VueZoomer from 'vue-zoomer'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import PanelPdfViewer from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelPDFviewer'

export default {
  components: {
    PanelPdfViewer,
    VideoPlayer,
    VZoomer: VueZoomer.Zoomer
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: null
    },
    emitDownload: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: true,
      widthSpace: 200,
      heightSpace: 200,
      hasSpace: false
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    isPdf() {
      if (!this.file) return false
      const parts = this.file?.filename?.split('.')
      return parts && parts.at(-1) === 'pdf'
    },

    isImage() {
      if (!this.file) return false
      return this.file.type === 'image'
    },

    isVideo() {
      if (!this.file) return false
      return this.file.type === 'video'
    },

    fileViewUrl() {
      if (!this.file) return ''
      return ServiceStorage.getFileUrl(this.file, this.file.token || this.user.token)
    }
  },

  mounted() {
    console.log('previwing file :>> ', this.file)
    this.calculateSpace()
  },

  methods: {
    calculateSpace() {
      const space = this.$refs['space-available']
      if (space) {
        const spaceHeight = window.innerHeight - 200
        const spaceWidth = space.clientWidth
        this.heightSpace = spaceHeight
        this.widthSpace = spaceWidth
        this.$nextTick(() => {
          this.hasSpace = true
        })
      }
    },
    downloadFile(file) {
      if (!file) return
      if (this.emitDownload) {
        this.$emit('download', file)
      } else {
        const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
        utils.downloadFile(url, file.filename)
        this.$emit('downloaded', file)
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
		"nopreview": "Sem prré-visualização disponível"
  },
  "en": {
    "close": "Close",
		"nopreview": "No preview available"
  }
}
</i18n>
