<template>
  <fw-panel :title="$t('new_document')">
    <div class="flex flex-col">
      <b-field :label="$t('title')">
        <b-input v-model="title"></b-input>
      </b-field>

      <b-field v-if="!showSearch && showLanguages" :label="$t('select_an_option')" class="mt-1">
        <div class="flex gap-3 items-center">
          <b-radio v-model="language" name="language" native-value="pt">Português</b-radio>
          <b-radio v-model="language" name="language" native-value="en-GB"> Inglês (Reino Unido) </b-radio>
          <fw-button v-if="!showSearch" type="xlight" class="" @click.native="showLanguageSearch()">
            {{ $t('see_all_languages') }}
          </fw-button>
        </div>
      </b-field>

      <b-field v-if="showSearch && showLanguages" :label="$t('select_an_option')">
        <b-autocomplete
          ref="languageSearch"
          v-model="languageSearch"
          :placeholder="$t('search_language_example')"
          :data="filteredLanguage"
          :clearable="true"
          field="name"
          :append-to-body="true"
          :open-on-focus="true"
          @select="option => (language = option.id)"
        >
        </b-autocomplete>
      </b-field>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button
        :type="!allowCreate || loading ? 'disabled' : 'primary'"
        :disable="!allowCreate || loading"
        class="w-32"
        @click.native="create()"
      >
        {{ $t('create') }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import ServicePages from '../../services/ServicePages'
import locales from '@/fw-modules/fw-core-vue/utilities/locales.json'
import ServiceBuckets from '../../../buckets/services/ServiceBuckets'
export default {
  name: 'ModalNewPage',
  props: {
    context: {
      type: String,
      required: true
    },
    contextKey: {
      type: String
    },
    injectPayload: {
      type: Object,
      default: () => {}
    },
    redirectAfterCreate: {
      type: Boolean,
      default: true
    },
    showLanguages: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: '',
      language: 'pt',
      loading: false,
      showSearch: false,
      availableLanguages: locales,
      languageSearch: '',
      isOther: false
    }
  },
  computed: {
    allowCreate() {
      return this.title.length > 0 && this.language != null
    },
    filteredLanguage() {
      return this.availableLanguages.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.languageSearch.toLowerCase()) >= 0
        )
      })
    }
  },
  watch: {
    isOther() {
      if (this.isOther) {
        this.language = null
      } else {
        this.language = 'pt'
      }
    },
    language() {
      if (this.language != 'pt' && this.language != 'en-GB') {
        this.showSearch = true
      } else {
        this.showSearch = false
      }
    }
  },
  methods: {
    showLanguageSearch() {
      this.showSearch = true
      this.language = null
      //focus
      this.$nextTick(() => {
        this.$refs.languageSearch.focus()
      })
    },
    async create() {
      //verify name
      if (this.title.length == 0) {
        return
      }
      this.loading = true

      if (this.context == 'bucket') {
        //content.fw.dev.ucframework.pt/v1/pages
        try {
          let result = await ServiceBuckets.addItem(this.contextKey, {
            page_title: this.title,
            language: this.language
          })
          //console.log(result.new_items[0].document_key)

          if (this.redirectAfterCreate) {
            this.$router.push({ name: 'content-pages-editor', params: { key: result.new_items[0].document_key } })
            return
          }
          this.$emit('created', result)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      } else {
        try {
          let result = await ServicePages.createPage({
            language: this.language,
            title: this.title,
            context: this.context,
            context_key: this.contextKey,
            ...this.injectPayload
          })
          if (this.redirectAfterCreate) {
            this.$router.push({ name: 'content-pages-editor', params: { key: result.page.key } })
            return
          }
          //console.log(result)
          this.$emit('created', result.page)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    },
    cancel() {
      if (!this.loading) {
        this.$emit('close')
      }
    }
  }
}
</script>

<i18n>
{
  "en": {
    "new_document": "New document",
    "see_all_languages": "See all languages",
    "title": "Title",
    "new_document_translation": "New document translation",
    "select_an_option": "Select a language",
    "search_language_example": "e.g. English - American",
    "cancel": "Cancel",
    "create": "Create"
  },
  "pt": {
    "new_document": "Novo documento",
    "title": "Título",
    "see_all_languages": "Ver todos os idiomas",
    "new_document_translation": "Nova tradução de documento",
    "select_an_option": "Selecione um idioma",
    "search_language_example": "exemplo, English - American",
    "cancel": "Cancelar",
    "create": "Criar"
  }
}
</i18n>
